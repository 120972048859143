import { ref, watch } from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default function useCampaignList() {
  const toast = useToast()

  const refCampaignListTable = ref(null)

  const tableColumns = [
    { key: 'token', label: 'Id', sortable: false },
    { key: 'name', label: 'Nome da campanha', sortable: false },
    { key: 'conversionUrl', label: 'URL de conversão', sortable: false },
    { key: 'campaignType', label: 'Tipo da campanha', sortable: false },
    { key: 'benefitIndicator', label: 'Benefício da campanha', sortable: false },
    { key: 'status', label: 'Status', sortable: false },
    { key: 'actions', label: 'Ações' },
  ]
  const sortBy = ref('token')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const inProcessing = ref(false)
  const isActiveCompany = ref(true)

  const refetchData = () => {
    refCampaignListTable.value.refresh()
  }
  watch([statusFilter], () => {
    refetchData()
  })

  const getActiveCompany = () => {
    useJwt.getActivePlan()
      .then(response => {
        isActiveCompany.value = response.data.result.activeCompany
        // console.log(response.data.result.activeCompany)
      })
      .catch(error => error)
  }

  const fetchCampaigns = (ctx, callback) => {
    store
      .dispatch('app-campaign/fetchCampaigns', {
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const campaigns = response

        callback(campaigns)
      })
      .catch(error => {
        // console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching campaigns' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      })
  }

  const handleStatusChange = (token, status) => {
    store.dispatch('app-campaign/handleStatusChange', {
      token,
      status,
    })
      .then(toast({
        component: ToastificationContent,
        props: {
          title: 'Status da campanha alterado com sucesso',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      }))
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        inProcessing.value = true
        setTimeout(() => {
          refetchData()
          inProcessing.value = false
        }, 300)
      })
  }

  const handleArchiveCampaing = (token, status) => {
    store.dispatch('app-campaign/handleArchiveCampaing', {
      token,
      status,
    })
      .then(setTimeout(refetchData(), 300))
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro ao tentar excluir a campanha',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => refetchData())
  }

  const getCampaignDetails = token => {
    store.dispatch('app-campaign/getCampaignDetails', {
      token,
    })
      .then()
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro ao tentar buscar os dados da campanha',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const toggleIsDuplicatingCampaing = status => {
    store.dispatch('app-campaign/toggleIsDuplicatingCampaing', status)
      .then()
  }

  return {
    toggleIsDuplicatingCampaing,
    getCampaignDetails,
    handleArchiveCampaing,
    handleStatusChange,
    fetchCampaigns,
    refCampaignListTable,
    tableColumns,
    sortBy,
    isSortDirDesc,
    statusFilter,
    refetchData,
    inProcessing,
    getActiveCompany,
    isActiveCompany,
  }
}
