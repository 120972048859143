<template>
  <section>
    <b-row class="mb-2">
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <!-- <h4>Suas campanhas :)</h4> -->
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          v-if="$can('edit', 'campaign')"
          variant="primary"
          @click="gotoToPageNewCampaing"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Nova campanha</span>
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      :show="isAPICallInProgress"
      rounded="sm"
    >
      <b-card no-body>
        <b-table
          ref="refCampaignListTable"
          responsive
          empty-text="Não existem campanhas configuradas"
          class="position-relative"
          primary-key="token"
          :items="fetchCampaigns"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          show-empty
        >
          <template #cell(token)="data">
            <b-link
              href="javascript:void(0)"
              class="font-weight-bold"
              @click="showDetaislCampaing(data.value)"
            >
              {{ data.value }}
            </b-link>
          </template>
          <template #cell(campaignType)="data">
            {{ data.item.campaignType === 'leadGenerate' ? 'Geração de Lead' : 'Geração de Venda' }}
          </template>
          <template #cell(benefitIndicator)="data">
            {{
              data.item.benefitIndicator.description === null || data.item.benefitIndicator.description === '' ?
                data.item.benefitIndicator.format === 'fixed' ? `R$${data.item.benefitIndicator.amount} por indicação` : `${data.item.benefitIndicator.amount}% do valor da venda`
                : data.item.benefitIndicator.description
            }}
          </template>
          <template #cell(status)="data">
            <b-form-checkbox
              v-if="$can('edit', 'campaign')"
              :checked="data.value === 'ATIVADA'"
              class="custom-control-success"
              name="check-button"
              switch
              :disabled="inProcessing || !isActiveCompany"
              @change="handleStatusChange(data.item.token, $event)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
            <small v-else>
              {{ data.item.status }}
            </small>
          </template>
          <template #cell(actions)="{item: {token}}">
            <div>

              <b-button
                variant="flat-primary"
                class="btn-icon rounded-circle"
                :disabled="inProcessing || !isActiveCompany"
                @click="showDetaislCampaing(token)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="$can('edit', 'campaign')"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                href="javascript:void(0)"
                :disabled="inProcessing || !isActiveCompany"
                @click="archiveCampaing(token)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-overlay>

    <!-- details modal -->
    <b-modal
      id="modal-1"
      v-model="showModalDetails"
      title="Detalhes da campanha"
      centered
      hide-footer
      size="lg"
      @hidden="resetModal"
    >

      <app-collapse accordion>
        <app-collapse-item title="Campanha">
          <b-row class="pr-2 pl-2">
            <b-col cols="6">
              <div style="display: -webkit-inline-box">
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Nome da Campanha:</strong> <br>
                  {{ getCampaignTemp.name }}
                </p>
              </div>

              <div style="display: -webkit-inline-box">
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Página de Conversão:</strong> <br>
                  {{ getCampaignTemp.conversionUrl }}
                </p>
              </div>

              <template v-if="getCampaignTemp.salesButton.show !== null">
                <div style="display: -webkit-inline-box">
                  <feather-icon
                    icon="CheckIcon"
                    size="15"
                    class="mr-1"
                    color="#6270FF"
                  />
                  <p>
                    <strong>Status botão de vendas</strong>
                    <br>
                    <template v-if="!isEditingSalesButton">
                      {{ getCampaignTemp.salesButton.show === false || getCampaignTemp.salesButton.show === null ? "Desativado" : "Ativado" }}
                    </template>

                    <b-form-checkbox
                      v-else
                      v-model="salesButton.show"
                      checked="true"
                      class="custom-control-success"
                      name="check-renovation"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </p>
                </div>
                <validation-observer ref="salesButtonForm">
                  <div style="display: -webkit-inline-box">
                    <feather-icon
                      icon="CheckIcon"
                      size="15"
                      class="mr-1"
                      color="#6270FF"
                    />
                    <p>
                      <strong>Texto do botão de vendas</strong>
                      <br>

                      <template v-if="!isEditingSalesButton">
                        {{ getCampaignTemp.salesButton.text ? getCampaignTemp.salesButton.text : "Não determinado" }}
                      </template>
                      <template v-else>
                        <validation-provider
                          #default="{ errors }"
                          name="texto do botão"
                          rules="required|max:40"
                        >
                          <b-form-input
                            v-model="salesButton.text"
                            :state="errors.length > 0 ? false:null"
                            type="text"
                            placeholder="Cadastrar indicação"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </template>

                    </p>
                  </div>
                  <div style="display: -webkit-inline-box">
                    <feather-icon
                      icon="CheckIcon"
                      size="15"
                      class="mr-1"
                      color="#6270FF"
                    />
                    <p>
                      <strong>
                        Página de redirecionamento do botão de vendas
                        <span
                          id="popover-url"
                          class="pr-1"
                        >
                          <feather-icon
                            size="12"
                            icon="HelpCircleIcon"
                          />
                        </span>
                        <b-popover
                          target="popover-url"
                          variant="primary"
                          triggers="hover"
                          placement="top"
                        >
                          <template #title>
                            <span>Atenção</span>
                          </template>

                          <div class="p-1 d-flex flex-column align-items-start">
                            <b-row class="justify-content-center align-items-center">
                              <span class="text-dark">Para o funcionamento correto do botão de vendas, o link deve obrigatoriamente iniciar com <b>https://</b></span>
                            </b-row>
                          </div>
                        </b-popover>
                      </strong> <br>
                      <template v-if="!isEditingSalesButton">
                        {{ getCampaignTemp.salesButton.link ? getCampaignTemp.salesButton.link : "Não determinado" }}
                      </template>

                      <template v-else>
                        <validation-provider
                          #default="{ errors }"
                          name="endereço da página de conversão"
                          rules="required|regex:https?:\/\/[^https:\/\/]"
                        >
                          <b-form-input
                            v-model="salesButton.link"
                            :state="errors.length > 0 ? false:null"
                            type="text"
                            style="display: flex"
                            placeholder="Informe o endereço da página"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </template>

                    </p>
                  </div>
                </validation-observer>
              </template>

              <!-- BOTÃO DE EDITAR BOTÃO DE VENDAS -->
              <div
                v-if="getCampaignTemp.salesButton.show !== null"
                class="mt-1"
                style="display: block"
              >
                <b-button
                  v-if="!isEditingSalesButton"
                  variant="outline-primary"
                  class="btn-icon"
                  @click="toggleSalesButtonConfigs"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="15"
                  />
                  Editar botão de vendas
                </b-button>

                <b-button
                  v-else
                  variant="outline-primary"
                  class="btn-icon"
                  @click="updateSalesButtonConfigs(getCampaignTemp.token)"
                >
                  <template v-if="!isAPICallInProgress">
                    <feather-icon
                      icon="SaveIcon"
                      size="15"
                    />
                    Salvar
                  </template>

                  <template v-else>
                    <feather-icon
                      style="animation: rotate 1s ease infinite"
                      icon="LoaderIcon"
                      size="15"
                    />
                  </template>
                </b-button>
              </div>

            </b-col>
            <b-col cols="6">
              <div style="display: -webkit-inline-box">
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Tipo da Campanha:</strong> <br>
                  {{ getCampaignTemp.campaignType === 'leadGenerate' ? 'Geração de Lead' : 'Geração de Venda' }}
                </p>
              </div>
            </b-col>
          </b-row>
        </app-collapse-item>
        <app-collapse-item title="Recompensa do indicador">
          <b-row class="pr-2 pl-2">
            <b-col cols="6">
              <div style="display: -webkit-inline-box">
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Maneira de recompensar:</strong> <br>
                  {{ getCampaignTemp.benefitIndicator.type === 'money' ? 'Dinheiro' : 'Cupom' }}
                </p>
              </div>

              <div
                v-if="getCampaignTemp.benefitIndicator.type === 'money'"
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>
                     {{ getCampaignTemp.benefitIndicator.format === 'fixed' ? 'Valor da Recompensa:' : 'Percentual da Compra:' }}
                   </strong> <br>
                  {{ getCampaignTemp.benefitIndicator.format === 'fixed' ? `R$${getCampaignTemp.benefitIndicator.amount},00` : `${getCampaignTemp.benefitIndicator.amount}%` }}
                </p>
              </div>

              <div
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Regulamento da campanha: </strong> <br>
                  <span v-html="getCampaignTemp.benefitIndicator.regulation" />
                </p>
              </div>
            </b-col>

            <b-col cols="6">
              <div
                v-if="getCampaignTemp.benefitIndicator.type === 'voucher'"
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Descrição do Cupom: </strong> <br> {{ getCampaignTemp.benefitIndicator.description }}</p>
              </div>

              <div
                v-if="getCampaignTemp.benefitIndicator.type === 'voucher'"
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Como resgatar</strong> <br>
                  {{
                    (getCampaignTemp.benefitIndicator.whereToRedeem.type === 'presential' && 'Presencial') ||
                      (getCampaignTemp.benefitIndicator.whereToRedeem.type === 'phone' && 'Telefone') ||
                      (getCampaignTemp.benefitIndicator.whereToRedeem.type === 'site' && 'Site') ||
                      (getCampaignTemp.benefitIndicator.whereToRedeem.type === 'other' && 'Outra')
                  }}
                </p>
              </div>

              <div
                v-if="getCampaignTemp.benefitIndicator.type === 'voucher'"
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Informação sobre como resgatar</strong> <br>
                  {{ getCampaignTemp.benefitIndicator.whereToRedeem.info }}
                </p>
              </div>

              <div
                v-if="getCampaignTemp.benefitIndicator.type === 'money'"
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Tipo de recompensa</strong> <br>
                  {{ getCampaignTemp.benefitIndicator.format === 'fixed' ? 'Valor fixo' : '% sobre a venda' }}
                </p>
              </div>
            </b-col>
          </b-row>
        </app-collapse-item>
        <app-collapse-item title="Benefício do indicado">
          <b-row class="pr-2 pl-2">
            <b-col cols="6">
              <div
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Benefício do indicado</strong> <br>
                  {{ getCampaignTemp.benefitIndicated.benefit }}
                </p>
              </div>

              <div
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Prazo de expiração do cupom</strong> <br>
                  {{ getCampaignTemp.benefitIndicated.daysToExpired !== 0 ? `${getCampaignTemp.benefitIndicated.daysToExpired} dias` : 'Indeterminado' }}
                </p>
              </div>

              <div
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Regulamento da campanha: </strong> <br>
                  <span v-html="getCampaignTemp.benefitIndicated.regulation" />
                </p>
              </div>
            </b-col>
            <b-col cols="6">
              <div
                style="display: -webkit-inline-box"
              >
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Como resgatar</strong> <br>
                  {{
                    (getCampaignTemp.benefitIndicated.whereToRedeem.type === 'presential' && 'Presencial') ||
                      (getCampaignTemp.benefitIndicated.whereToRedeem.type === 'phone' && 'Telefone') ||
                      (getCampaignTemp.benefitIndicated.whereToRedeem.type === 'site' && 'Site') ||
                      (getCampaignTemp.benefitIndicated.whereToRedeem.type === 'other' && 'Outra')
                  }}
                </p>
              </div>

              <div style="display: -webkit-inline-box">
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Informação sobre como resgatar</strong> <br>
                  {{ getCampaignTemp.benefitIndicated.whereToRedeem.info }}
                </p>
              </div>
            </b-col>
          </b-row>
        </app-collapse-item>
        <!--  -->
        <app-collapse-item title="Compartilhamento">
          <b-row class="pr-2 pl-2">
            <b-col cols="12">
              <div style="display: -webkit-inline-box">
                <feather-icon
                  icon="CheckIcon"
                  size="15"
                  class="mr-1"
                  color="#6270FF"
                />
                <p><strong>Texto de compartilhamento:</strong> <br>
                  {{ getCampaignTemp.sharedMessage }}
                </p>

              </div>
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>

      <b-button
        v-if="$can('edit', 'campaign')"
        variant="primary"
        class="d-flex justify-content-center mt-2"
        style="float: right"
        @click="duplicateCampaing"
      >
        <span class="align-middle">Duplicar campanha</span>
      </b-button>
    </b-modal>
    <!---->
  </section>

</template>

<script>
import { mapGetters } from 'vuex'

import {
  BLink, BButton, BRow, BCol, BCard, BTable, BFormCheckbox, BModal, VBModal, BOverlay, BFormInput, BPopover,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, regex } from '@validations'

import Ripple from 'vue-ripple-directive'

// import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import useJwt from '@/auth/jwt/useJwt'
import useCampaignList from './useCampaignList'
import campaignStoreModule from '../campaignStoreModule'

export default {
  name: 'CampaignList',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BLink,
    BFormCheckbox,
    BModal,
    AppCollapse,
    AppCollapseItem,
    BOverlay,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BPopover,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-campaign'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, campaignStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const {
      fetchCampaigns,
      tableColumns,
      sortBy,
      isSortDirDesc,
      refCampaignListTable,

      statusFilter,

      refetchData,
      handleStatusChange,
      handleArchiveCampaing,
      getCampaignDetails,
      toggleIsDuplicatingCampaing,
      inProcessing,
      isActiveCompany,
      getActiveCompany,
    } = useCampaignList()
    return {
      fetchCampaigns,
      tableColumns,
      sortBy,
      isSortDirDesc,
      refCampaignListTable,

      statusFilter,

      refetchData,
      handleStatusChange,
      handleArchiveCampaing,
      getCampaignDetails,
      toggleIsDuplicatingCampaing,
      inProcessing,
      isActiveCompany,
      getActiveCompany,
    }
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      showModalDetails: false,
      isEditingSalesButton: false,
      salesButton: {
        text: '',
        link: '',
        show: false,
      },
      required,
      regex,
    }
  },
  computed: {
    ...mapGetters({
      getCampaignTemp: 'app-campaign/getCampaignTemp',
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  mounted() {
    localize('pt_BR')
  },
  created() {
    this.toggleIsDuplicatingCampaing(false)
    this.getActiveCompany()
  },
  methods: {
    toggleSalesButtonConfigs() {
      this.salesButton = {
        text: this.getCampaignTemp.salesButton.text,
        show: this.getCampaignTemp.salesButton.show,
        link: this.getCampaignTemp.salesButton.link,
      }

      this.isEditingSalesButton = !this.isEditingSalesButton
    },
    updateSalesButtonConfigs(token) {
      this.$refs.salesButtonForm.validate().then(success => {
        if (success) {
          useJwt.updateSalesButton(token, { salesButton: { ...this.salesButton } })
            .then(() => {
              this.isEditingSalesButton = false
              this.getCampaignDetails(token)
            })
            .catch(error => error)
        }
      })
    },
    resetModal() {
      if (this.isEditingSalesButton) this.isEditingSalesButton = false
    },
    showDetaislCampaing(token) {
      this.getCampaignDetails(token)
      this.showModalDetails = !this.showModalDetails
    },
    duplicateCampaing() {
      this.toggleIsDuplicatingCampaing(true)
      this.$router.push({ name: 'campaign-new' })
    },
    gotoToPageNewCampaing() {
      this.$router.push({ name: 'campaign-new' })
    },
    // confirm texrt
    archiveCampaing(token) {
      this.$swal({
        title: 'Tem certeza que deseja excluir a campanha?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.handleArchiveCampaing(token)
          this.$swal({
            icon: 'success',
            title: 'Excluída!',
            text: 'Sua campanha foi excluída com sucesso!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>
